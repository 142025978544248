<template>
    <div class="public-job-item">
        <div class="public-job-item-header">
            <div class="job-title">
                <a
                    target="_blank"
                    :title="job.name"
                    class="job-title-link text-ellipsis"
                    :href="`/Headhunting/MyCompany.html#/opportunityJobDetail/${job.id}?jobId=${job.job.id}&userId=${job.job.recruiter.userId}`">
                    {{job.name}}
                </a>
                <!-- <span class="job-location text-ellipsis" :title="job.locationText">
                    [{{job.locationText}}]
                </span> -->
                <span class="job-customerName" :title="job.customerName">{{ job.customerName }}</span>
            </div>
        </div>
        <div class="public-job-item-body">
            <div class="job-intro">
                <div class="job-info">
                    <span class="job-info-item">{{job.minSalary == 0 && job.maxSalary == 0 ? '面议' : job.minSalary + 'K-' + job.maxSalary + 'K'}}</span>
                    <span class="job-info-item" v-if="job.minYearOfExperience == 0">经验不限</span>
                    <span class="job-info-item" v-else>{{job.minYearOfExperience | filterYearOfExperience}}经验</span>
                    <span class="job-info-item">{{job.minDegree | degreeFilter}}</span>
                </div>
                <div class="job-origin">
                    <span class="icon-text">原</span>
                    <span class="job">{{ job.job.name }}</span>
                    <span class="customer">{{ job.job.customerName }}</span>
                </div>
            </div>
            <div class="job-status">
                <div>
                    <span class="recruit-status" v-if="job.status == '0'">编辑中</span>
                    <span class="recruit-status" v-if="job.status == '3'">审核中</span>
                    <div v-if="job.status == '5'">
                        <span class="recruit-status no-pass">审核未通过</span>
                        <el-popover
                            placement="bottom"
                            width="240"
                            trigger="hover"
                            :content="job.rejectReason">
                            <img slot="reference" class="nopass-img" src="@src/assets/images/program-goal-warn.png" alt="">
                        </el-popover>
                    </div>
                    <span class="recruit-status" v-if="job.status == '7'">未发布</span>
                    <span class="recruit-status publish-status" v-if="job.status == '9'">发布中</span>
                    <span class="recruit-status" v-if="job.status == '11'">源职位已暂停</span>
                    <span class="recruit-status" v-if="job.status == '13'">源职位已完成</span>
                </div>
                <span class="job-date">
                    {{job.created | createDateFilter}} 发布 / {{job.updated | dateFilter}} 更新
                </span>
            </div>
            <div class="public-job-item-body-right">
                <template>
                    <avatar
                        class="user-avatar"
                        size="sm"
                        :src="job.job.recruiter.avatarUrl"
                        :userId="job.job.recruiter.userId"
                        :enableCard="true"
                        :enableLink="true"
                    ></avatar>
                    <div class="job-number">
                        <span title="投递">
                            投递 {{ job.jobStatisticInfo?.deliveredCount || 0 }}
                        </span>
                        <span title="收藏">
                            收藏 {{ job.jobStatisticInfo?.collectionCount || 0 }}
                        </span>
                        <span title="面试">
                            面试 {{ job.jobStatisticInfo?.interviewCount || 0 }}
                        </span>
                    </div>
                    <el-dropdown
                        placement="bottom"
                        v-if="job.job.recruiter.userId == userInfo.id"
                        @command="handlePublicJobMoreOperate">
                        <span
                            class="el-dropdown-link el-icon-more"
                            v-loading="moreOperateLoading"
                        ></span>
                        <el-dropdown-menu
                            class="job-more-opr-dropdown"
                            slot="dropdown">
                            <div>
                                <el-dropdown-item command="cancelAudit" v-if="job.canCancelAudit">撤回审核</el-dropdown-item>
                                <el-dropdown-item command="cancelPublish" v-if="job.canCancelPulish">取消发布</el-dropdown-item>
                                <el-dropdown-item command="submitAudit" v-if="job.canSubmitAudit">提交审核</el-dropdown-item>
                                <el-dropdown-item command="publish" v-if="job.canPulish">发布</el-dropdown-item>
                                <el-dropdown-item command="edit" v-if="job.canEdit">编辑</el-dropdown-item>
                            </div>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </div>
        </div>

        <famous-company-dialog
            ref="famousCompanyDialog"
            @editSuccess="$emit('updateParams')"
        />
    </div>
</template>

<script>
import moment from 'moment';
import Avatar from '#/component/common/avatar.vue';
import FamousCompanyDialog from '#/views/job-detail/component/famous-company-dialog.vue';
import DegreeKeyValue from '#/js/config/degreeKeyValue.json';
import PublicJobService from '#/js/service/publicJobService.js';

export default {
    components: {
        Avatar,
        FamousCompanyDialog
    },
    props: {
        job: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    data() {
        return {
            moreOperateLoading: false,
        };
    },
    mounted() {
        
    },
    filters: {
        degreeFilter(val) {
            return DegreeKeyValue[val];
        },
        filterYearOfExperience(val) {
            if(val == 1) {
                return '1-3年'
            } else if(val == 3) {
                return '3-5年'
            } else if(val == 5) {
                return '5-10年'
            } else if(val == 10) {
                return '10年以上'
            }
        },
        createDateFilter(val) {
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(val).format('YYYY-MM-DD');
            if(today === dateDay) {
                return '今天';
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        },
        dateFilter(val) {
            let now = new Date(),
                date = val;
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(date).format('YYYY-MM-DD'),
                nowTime = new Date().getTime(),
                dateTime = new Date(date).getTime(),
                oneHourTime = 60 * 60 * 1000,
                hour = now.getHours(),
                dateHours = new Date(date).getHours();
            if(today === dateDay) {
                if (dateTime > nowTime - oneHourTime * 4 && dateTime < nowTime - oneHourTime) {
                    return (hour - dateHours) + '小时前';
                } else if (dateHours == hour || (dateTime > nowTime && dateTime < nowTime + oneHourTime) || (dateTime < nowTime && dateTime > nowTime - oneHourTime)) {  // 目标时间与当前时间
                    let minutesDiff = Math.floor((dateTime - nowTime) / 60000);
                    if(minutesDiff == 0 || minutesDiff == 1) {
                        return '刚刚';
                    } else if(minutesDiff < 0) {
                        return (minutesDiff * -1) + '分钟前';
                    }
                } else {
                    return '今天';
                }
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        }
    },
    methods: {
        handlePublicJobMoreOperate(command) {
            switch(command) {
                case 'cancelAudit':
                    this.cancelAudit();
                    break;
                case 'cancelPublish':
                    this.cancelPublish();
                    break;
                case 'submitAudit':
                    this.submitAudit();
                    break;
                case 'publish':
                    this.publishPublicJob();
                    break;
                case 'edit':
                    this.getPublicJobEditInfo();
                    break;
            }
        },
        // 撤回审核
        cancelAudit() {
            this.moreOperateLoading = true;
            PublicJobService.cancelAudit(this.job.id)
                .then(res => {
                    shortTips('撤回审核成功');
                    this.moreOperateLoading = false;
                    this.$emit('updateParams');
                }).catch(err => {
                    console.log(err);
                    this.moreOperateLoading = false;
                });
        },
        // 取消发布
        cancelPublish() {
            // console.log('cancelPublish--------');
            this.moreOperateLoading = true;
            PublicJobService.cancelPublish(this.job.id)
                .then(res => {
                    shortTips('取消发布成功');
                    this.moreOperateLoading = false;
                    this.$emit('updateParams');
                }).catch(err => {
                    console.log(err);
                    this.moreOperateLoading = false;
                });
        },
        // 提交审核
        submitAudit() {
            this.moreOperateLoading = true;
            PublicJobService.submitAudit(this.job.id)
                .then(res => {
                    shortTips('提交审核成功');
                    this.moreOperateLoading = false;
                    this.$emit('updateParams');
                }).catch(err => {
                    console.log(err);
                    this.moreOperateLoading = false;
                });
        },
        // 发布
        publishPublicJob() {
            // console.log('publish--------');
            this.moreOperateLoading = true;
            PublicJobService.publishPublicJob(this.job.id)
                .then(res => {
                    shortTips('发布成功');
                    this.moreOperateLoading = false;
                    this.$emit('updateParams');
                }).catch(err => {
                    console.log(err);
                    this.moreOperateLoading = false;
                });
        },
        // 获取大厂职位编辑详情
        getPublicJobEditInfo() {
            PublicJobService.getPublicJobEditInfo(this.job.job.id)
                .then(res => {
                    // console.log(res);
                    let originalInfo = res.originalInfo;
                    let publicInfo = res.publicInfo;

                    this.$refs['famousCompanyDialog'].show(originalInfo, publicInfo, this.job.id);
                }).catch(err => {
                    console.log(err);
                }); 
        }
    }
};
</script>

<style lang="scss" scope>
.public-job-item {
    background-color: #fff;
    padding: 20px;
    border-bottom: 1px solid #eee;
    .text-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &:hover {
        background-color: #f6f6f6;
    }
    &-header {
        height: 40px;
        line-height: 40px;
        color: #333;
        font-size: 16px;
        .job-title {
            display: flex;
            height: 40px;
            &-link {
                max-width: 400px;
                margin-right: 15px;
                &:hover{
                    color: $primary;
                }
            }
            .job-location {
                max-width: 300px;
                margin-right: 10px;
            }
            .job-customerName {
                font-size: 16px;
                color: #666666;
                max-width: 500px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    &-body {
        display: flex;
        // justify-content: space-between;
        color: #666;
        line-height: 20px;
        .job-intro {
            // width: 400px;
            flex: 1;
            margin-right: 60px;
            .job-info {
                margin-bottom: 10px;
                white-space: nowrap;
                .job-info-item {
                    border-right: 1px solid #d3d3d3;
                    padding-right: 3px;
                    margin-right: 5px;
                    &:nth-last-of-type(1) {
                        border-right: none;
                    }
                }
            }
            .job-origin {
                font-size: 14px;
                color: #333333;
                .icon-text {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    background: #F5F5F5;
                    border-radius: 2px;
                    color: #38BC9D;
                    text-align: center;
                    margin-right: 8px;
                }
                .job {
                    margin-right: 20px;
                }
            }
            .job-firm-commission {
                color: #b9b9b9;
                .text-grey {
                    color: #666;
                }
            }

            .tip-icon {
                display: inline-block;
                width: 22px;
                height: 22px;
                margin-left: 5px;
                .font-icon {
                    width: 16px;
                    height: 16px;
                    margin-top: -3px;
                    opacity: 1;
                }
                .hover {
                    display: none;
                }
                &:hover {
                    .default {
                        display: none;
                    }
                    .hover {
                        display: inline-block;
                    }
                }
            }
        }
        .job-status {
            // width: 240px;
            flex: 1;
            margin-right: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .job-customer {
                // width: 90px;
                width: 160px;
                margin-bottom: 10px;
                &.no-link {
                    cursor: default;
                    &:hover {
                        color: #333;
                    }
                }
                &:hover {
                    color: #38BC9D;
                }
            }
            .recruit-status {
                color: #999999;
                font-size: 18px;
            }
            .publish-status {
                color: #39BD9E;
            }
            .no-pass {
                color: #FF493C;
            }
            .nopass-img {
                margin-left: 10px;
                vertical-align: text-top;
            }
            .job-date {
                color: #999;
                font-size: 14px;
                margin-top: 20px;
            }
        }
        &-right {
            flex: 1;
            display: flex;
            .apply-again {
                cursor: pointer;
                color: #39BD9E;
                font-size: 16px;
            }
        }
        .user-avatar {
            margin-right: 10px;
            margin-top: -6px;
            width: 30px;
            height: 30px;
            .avatar-sm {
                width: 28px;
                height: 28px;
            }
        }
        .job-number {
            span {
                display: inline-block;
                min-width: 40px;
                margin-right: 25px;
            }
        }
        .el-dropdown {
            height: 30px;
            .el-dropdown-link {
                color: #ccc;
                display: inline-block;
                width: 60px;
                text-align: center;
                line-height: 20px;
                font-size: 26px;
            }
        }
    }
}
</style>

<style lang="scss">
.job-tag-description.el-tooltip__popper.is-light {
    margin-left: 7px;
    color: #666;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .popper__arrow {
        border: 6px solid transparent;
        border-left: none;
        border-right-color: rgba(0, 0, 0, 0.2);
        &::after {
            border-right-color: #fff;
        }
    }
}
.commission-rate-text.el-tooltip__popper.is-light {
    margin-left: 8px;
    margin-top: 40px;
    .popper__arrow {
        display: none;
    }
}
.job-more-opr-dropdown.el-dropdown-menu {
    margin-top: 0;
    padding: 0;
    .el-dropdown-menu__item {
        text-align: center;
    }
}
</style>
