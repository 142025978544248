<template>
    <el-select
        :disabled="isDisabled"
        :filter-method="customerFilter"
        :multiple-limit="5"
        :placeholder="placeholder"
        @clear="handleCustomerChange({
            customerId: '',
            customerName: ''
        })"
        @visible-change="handleVisibleChange"
        class="customer-select-input"
        clearable
        collapse-tags
        filterable
        multiple
        popper-class="customer-select-shield"
        ref="selectOrShield"
        v-model="customerId"
    >
        <!-- @keyup.enter.native="handleKeyupEnter" -->
        <!-- <el-option
            v-for="customer in customerFilterList"
            :key="customer.customerId"
            :value="customer.customerId"
            :label="customer.customerName"
            :title="customer.customerName"
            @click.native="handleCustomerChange(customer)"
        ></el-option>-->
        <el-option
            :key="customer.customerId"
            :label="customer.customerName"
            :value="customer.customerId"
            v-for="customer in customerFilterList"
        >
            <div class="opt-item">
                <div
                    :title="customer.customerName"
                    class="opt-item-l ellipsis"
                >{{customer.customerName}}</div>
                <div class="opt-item-r">
                    <i class="el-icon-check"></i>
                </div>
            </div>
            <!-- <span style="height: 40px; display: block; width: 100%; background: #eee; "
                @click.stop="haha(customer.customerId)"
            >{{customer.customerName}}</span>-->
        </el-option>
        <div class="select-btns-wrap">
            <el-button
                @click="handleBtnHold(0)"
                class="btn shield"
            >屏蔽</el-button>
            <el-button
                @click="handleBtnHold(1)"
                class="btn search"
            >搜索</el-button>
        </div>
    </el-select>
</template>

<script>
import customerListService from '#/js/service/customerListService.js';


// 客户选择和屏蔽组件
export default {
    name: "customerSelect",
    props: {
        firmId: {
            type: String,
            default: ""
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择客户"
        }
    },
    data () {
        return {
            customerId: '',
            customerFilterList: [],
            customerList: [],

            isBtnTaped: false,
        };
    },
    mounted () {
        this.getCustomerList();
    },
    methods: {
        getCustomerList () {
            let params = {};
            if (this.firmId) {
                params.firmId = this.firmId;
            }
            customerListService.getMyCompanyCustomers(params)
                .then(res => {
                    res.forEach(item => {
                        delete item.subCustomers;
                    });
                    this.customerList = res;
                    this.customerFilterList = res;
                }).catch(err => {
                    console.log(err);
                });
        },
        customerFilter (val) {
            if (val) {
                this.customerFilterList = this.customerList.filter(customer =>
                    customer.customerName.toLowerCase().indexOf(val.toLowerCase()) > -1
                );
            } else {
                this.customerFilterList = this.customerList;
            }
        },
        getSelectedCustomer () {
            return this.customerList.find(item => item.customerId === this.customerId);
        },
        handleCustomerChange (customer) {
            this.customerId = customer.customerId;
            this.$emit('customerSelected', {
                customerId: this.customerId,
                customerName: customer.customerName
            });
        },
        handleStopClick (customer) {
            console.log(`cusomter:`, customer);
            // this.customerId = customer.customerId;
        },
        // 提交类型：0为屏蔽，1为搜索
        handleBtnHold (type) {
            this.isBtnTaped = true;
            // const customer = this.customerFilterList.find(item => item.customerId == this.customerId);
            // if(!customer) return shortTips(`请选择客户再提交`);

            // this.$emit('customerSelected', {
            //     customerId: this.customerId, 
            //     customerName: customer.customerName
            // }, '', type);

            const _customers = this.customerFilterList.filter(it => this.customerId.includes(it.customerId));
            if(_customers.length == 0) return shortTips(`请选择客户再提交`);

            this.$emit('customers-selected', _customers, '', type);
            this.$refs.selectOrShield.blur();
        },
        // 兼容enter快捷键选择模式
        handleKeyupEnter () {
            const _oCustomer = this.customerFilterList.find(item => item.customerId == this.customerId);
            this.$emit('customerSelected', {
                customerId: _oCustomer.customerId,
                customerName: _oCustomer.customerName
            });
        },
        reset () {
            this.customerId = '';
            this.customerFilter();
        },
        handleVisibleChange (isVisible) {
            // if (!isVisible && !this.isBtnTaped) {
            //     this.customerId = '';
            // }
            // this.isBtnTaped = false;
        }
    }
}
</script>

<style lang="scss">
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.customer-select-input{
    .el-select__tags{
        // margin-left: 82px;
        padding-left: 82px;
        // .el-select__tags-text {
        //     display: inline-block;
        //     max-width: 200px;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }
    }
}
.customer-select-shield {
    max-width: 380px;
    .el-select-dropdown__list {
        padding-bottom: 40px !important;

        .selected {
            .opt-item {
                .opt-item-r {
                    > i {
                        display: block;
                    }
                }
            }
        }
        .opt-item {
            width: 100%;
            height: 40px;
            font-size: 0;
            .opt-item-l {
                display: inline-block;
                width: 85%;
                font-size: 14px;
                vertical-align: top;
            }
            .opt-item-r {
                display: inline-block;
                width: 13%;
                font-size: 14px;
                line-height: 40px;
                text-align: right;
                vertical-align: middle;
                > i {
                    display: none;
                }
            }
        }

        .select-btns-wrap {
            height: 40px;
            background: red;
            position: absolute;
            bottom: -1px;
            right: 0;
            width: 100%;
            .btn {
                width: 50%;
                height: 40px;
                line-height: 40px;
                margin: 0;
                background: white;
                font-size: 14px;
            }
            .shield {
                color: #38bc9d;
                &:hover {
                    background: #fefefe;
                }
            }
            .search {
                background: #46d6b5;
                color: white;
                &:hover {
                    background: #52e0bf;
                }
            }
        }
    }
}
</style>
