<template>
    <div class="delivery-list">
        <el-table
            ref="deliveryListTable"
            v-loading="loading"
            :data="deliveryList"
            border
            style="width: 100%"
            tooltip-effect="light"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                width="42"
                align="center"
                type="selection"
                header-align="center"
            ></el-table-column>
            <el-table-column
                prop="realName"
                align="left"
                width="178"
                label="姓名"
                :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                prop="created"
                align="left"
                width="166"
                label="投递时间"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.created | timeFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="publicJobName"
                align="left"
                width="162"
                label="投递岗位"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <a class="table-a" :href="`/Headhunting/MyCompany.html#/opportunityJobDetail/${scope.row.publicJobId}?jobId=${scope.row.jobId}&userId=${userInfo.id}`" target="_blank">{{ scope.row.publicJobName }}</a>
                </template>
            </el-table-column>
            <el-table-column
                prop="customerName"
                align="left"
                width="162"
                label="大厂公司"
                :show-overflow-tooltip="true"
            >
            </el-table-column>
            <!-- <el-table-column
                prop="yearOfExperience"
                align="left"
                width="100"
                label="工作经验"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.yearOfExperience ? scope.row.yearOfExperience + '年' : '' }}</span>
                </template>
            </el-table-column> -->
            <el-table-column
                prop="birthday"
                align="left"
                width="95"
                label="年龄"
            >
                <template slot-scope="scope">
                    <span>{{ birthdayToAge(scope.row.birthday) ? birthdayToAge(scope.row.birthday) + '岁' : '' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="degree"
                align="left"
                width="120"
                label="学历"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.degree | degreeFilter }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="workExperiences"
                align="left"
                min-width="350"
                :show-overflow-tooltip="true"
                label="最近工作"
            >
                <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.workExperiences" :key="'workExperiences_'+index">
                        <span>{{ item.startDate | dateFilter }}-{{ item.endDate | dateFilter }}</span>
                        <i class="line">|</i>

                        <span>{{ item.company }}</span>
                        <i class="line">|</i>

                        <span>{{ item.title }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="status"
                align="left"
                width="110"
                label="状态"
            >  
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 0">待处理</span>
                    <span v-if="scope.row.status == 1">
                        <a class="table-a followed" :href="`/Headhunting/MyCompany.html#/candidateDetail/${scope.row.candidateId}/recommendation/${scope.row.recommendId}`" target="_blank">已跟进>></a>
                    </span>
                    <span v-if="scope.row.status == 2">不通过</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div class="item-option">
                        <span @click="handleOption(scope.row, 'view')">查看</span>
                        <i class="line" v-if="scope.row.status == 0 && scope.row.followUpUsers.includes(userInfo.id)">|</i>

                        <span @click="handleOption(scope.row, 'follow')" v-if="scope.row.status == 0 && scope.row.followUpUsers.includes(userInfo.id)">跟进</span>
                        <i class="line" v-if="scope.row.status == 0 && scope.row.followUpUsers.includes(userInfo.id)">|</i>

                        <span 
                            @click="handleOption(scope.row, 'refuse')" 
                            style="color: #fc7859" 
                            v-if="scope.row.status == 0 && scope.row.followUpUsers.includes(userInfo.id)"
                        >不通过</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
            :current-page="pager.current"
            :page-sizes="[10, 30, 50]"
            :page-size="pager.take"
            class="deliver-list-pagination"
            layout="prev, pager, next, slot, total, sizes"
            :total="pager.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
            <span class="pagination-text">
                <span>
                    前往
                    <el-input
                        v-model="pagerJump"
                        @keyup.enter.native="handlePagerJump(pagerJump)"
                    ></el-input>
                    页
                </span>
                <span @click="handlePagerJump(pagerJump)"> 跳转 </span>
            </span>
        </el-pagination>

        <el-dialog
            title="跟进简历"
            class="fllow_resume_dialog"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="560px"
            :before-close="handleClose">
            <div class="content">
                <p class="tip">候选人已存在于人才库，去对比补充后跟进</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="goToCompareResume">去对比补充</el-button>
            </span>
        </el-dialog>

        <fllow-resume-dialog 
            ref="fllowResumeDialog"
            @update-deliver-list="updateDeliverList"
        ></fllow-resume-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { getAgeFromBirthday } from '#/js/util/tools.js';
import DegreeKeyValue from '#/js/config/degreeKeyValue.json';
import FllowResumeDialog from '../component/fllow_resume_dialog.vue';
import PublicJobService from '#/js/service/publicJobService.js';
import resumeSingleService from '#/js/service/resumeSingleService.js';
import cityProvinceMapData from '#/js/config/cityProvinceMap.json';

export default {
    props: {
        deliveryList: Array,
        listLoading: Boolean,
    },
    components: {
        FllowResumeDialog,
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,

            deliveryId: '',
            candidatId: '',
            resumeId: '',
            jobId: '',
            resumeData: {
                "__RequestVerificationToken": this.$store.state.verificationToken.verificationToken,
                "isPersonalCreate": false,
                "isExisted":false,
                "existedId":null,
                "source": null,
                "province": "",
                "fileId": "",
                "autoApprovelTrading": false,
                "jobHoppingOccasion": null,
                "employerInfo": null,
            },

            pager: {
                current: 1,
                take: 10,
                total: 0
            },
            pagerJump: 0,

            multipleSelection: [],
        };
    },

    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        checkAll(){
            return this.deliveryList.length > 0 ? this.deliveryList.length == this.multipleSelection.length : false;
        },
    },
    filters: {
        timeFilter(val) {
            return val ? moment(val).format('YYYY-MM-DD HH:mm') : '';
        },
        dateFilter(val) {
            // return val ? moment(val).format('YYYY.MM.DD') : '';
            return val ? moment(val).format('YYYY.MM') : '';
        },
        degreeFilter(val) {
            return DegreeKeyValue[val];
        },
    },
    watch: {
        listLoading: {
            handler(val) {
                this.loading = val;
            },
            immediate: true
        }
    },

    mounted() {
        
    },

    methods: {
        birthdayToAge(birthday) {
            return getAgeFromBirthday(birthday);
        },

        search(slient) {
            let params = {};
            if(!slient) {
                this.pager.current = 1;
            }
            params.start = (this.pager.current - 1) * this.pager.take;
            params.take = this.pager.take;

            this.$emit('refresh', params, slient);
        },
        // 分页
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            this.search(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
           this.search(true);
        },
        handlePagerJump(pagerJump) {
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.take)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },

        handleSelectionChange(val) {
            console.log(val);
            this.multipleSelection = val;
            this.$emit('get-multiple-selection', this.multipleSelection, this.checkAll);
        },
        handleOption(item, type) {
            console.log(item);
            this.deliveryId = item.id;
            this.jobId = item.jobId;
            if(type == 'view') { // 查看
                // let routeUrl = `/#/opportunityResumeDetail/${item.resumeId}`;
                let routeUrl = `/#/opportunityResumeDetail/${item.id}`;
                window.open(routeUrl, '_blank');
            } else if(type == 'follow') { // 跟进
                // 判断是否跟进过？（点过跟进没有去推荐的情况）
                if(item.candidateId) { // 跟进过
                    // 跟进过就直接去推荐
                    this.$refs.fllowResumeDialog.show(item, item.candidateId);
                } else { // 没跟进
                    // 2、判断人才库里面是否有这份简历
                    this.validateFirmExist(item);
                }
            } else { // 不通过
                this.$confirm(`确定不通过该简历吗?`, '不通过确认', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.loading = true;
                    PublicJobService.deliverNoPass(this.deliveryId)
                        .then(res => {
                            shortTips('操作成功!');
                            this.loading = false;

                            // 刷新列表
                            let params = {};
                            params.start = (this.pager.current - 1) * this.pager.take;
                            params.take = this.pager.take;
                            this.$emit('refresh', params);
                        }).catch(err => {
                            console.log(err);
                            this.loading = false;
                        });
                }).catch(() => {
                    
                });
            }
        },
        validateFirmExist(item) {
            this.loading = true;
            //个人库在提交后判断是否存在于企业库
            let postData = {
                realName: item.realName,
                mobile: item.mobile,
                email: item.email,
                gender: item.gender
            }
            resumeSingleService.resumeValidateRepeat(postData)
                .then((res)=>{
                    console.log(res);
                    this.loading = false;
                    // 3、没有就把简历保存到人才库，有的话就去对比简历
                    if(!res) { // 人才库没有
                        // 获取该条简历详情
                        this.getdeliverResumedetail(item);
                    } else {
                        this.candidatId = res.candidatId;
                        this.resumeId = res.resumeId;

                        this.dialogVisible = true;
                    }
                })
                .catch((err)=>{
                    console.log(err);
                    let result = err.data;
                    this.loading = false;

                    // 简历已经存在人才库的话去对比简历
                    this.candidatId = result.candidatId;
                    this.resumeId = result.resumeId;

                    this.dialogVisible = true;
                });
        },
        getdeliverResumedetail(item) {
            this.loading = true;
            PublicJobService.getDeliverResumeDetail(this.deliveryId)
                .then(res => {
                    console.log(res);
                    this.loading = false;
                    this.createCandidate(item, res);
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        //根据城市获取省份id
        getProvinceByCity(cid){
            let pId = cityProvinceMapData[cid];
            return {
                id: pId
            };
        },
        // 把投递简历保存到人才库
        createCandidate(item, resumeInfo) {
            this.loading = true;
            if(resumeInfo.city && !this.resumeData.province) {
                this.resumeData.province = parseInt(this.getProvinceByCity(resumeInfo.city).id);
            }
            let params = Object.assign({}, resumeInfo, this.resumeData);
            params.PublicJobDeliveryId = this.deliveryId;
            
            resumeSingleService.createCandidate(params)
                .then(res => {
                    this.loading = false;

                    // 刷新列表
                    // （如果不推荐的话，下次点跟进就已经刷新可以获取到新的数据状态。 点了跟进 人才已入库 不推荐再点跟进会显示人才库已存在要去对比简历，但应该直接弹出推荐弹窗去推荐）
                    let params = {};
                    params.start = (this.pager.current - 1) * this.pager.take;
                    params.take = this.pager.take;
                    this.$emit('refresh', params);

                    // 简历保存到人才库成功弹出推荐弹窗
                    this.$refs.fllowResumeDialog.show(item, res.candidateId);
                })
                .catch((err)=>{
                    console.log(err);
                    this.loading = false;
                    shortTips('跟进失败!');
                })
        },

        handleClose() {
            this.dialogVisible = false;
        },
        goToCompareResume() {
            this.dialogVisible = false;
            
            window.open(`/#/candidate/cocUpdate?candidatId=${this.candidatId}&resumeId=${this.resumeId}&deliveryId=${this.deliveryId}&jobId=${this.jobId}`, '_blank');
        },

        updateDeliverList() {
            // 刷新列表
            let params = {};
            params.start = (this.pager.current - 1) * this.pager.take;
            params.take = this.pager.take;
            this.$emit('refresh', params);
        }
    },
};
</script>

<style lang="scss" scoped>
.delivery-list {
    padding: 20px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    min-height: calc(100vh - 306px);

    /deep/ .el-table {
        thead tr th {
            background-color: #e8e8e8;
            .cell {
                color: #666;
                font-weight: bold;
                padding: 0 20px;
                .el-checkbox {
                    display: none;
                }
            }
        }
        td .cell {
            padding: 0 20px;
        }
        th,
        td {
            padding: 8px 0;
        }

        .el-table-column--selection {
            border-right: none;
            .cell {
                padding: 0;
                .el-checkbox__inner {
                    border-color: #999;
                }

                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #fff;
                    border-color: $primary;
                    &::after {
                        border-color: $primary;
                    }
                }
            }
        }
    }

    .table-a {
        color: #606266;
        &:hover {
            color: #38bc9d;
        }
    }
    .followed {
        &:hover {
            text-decoration: underline;
        }
    }

    .item-option {
        span {
            cursor: pointer;
            display: inline-block;
            color: #38bc9d;
            font-size: 14px;
        }
    }
    .line {
        display: inline-block;
        margin: 0 10px;
        cursor: text;
        color: #cccccc;
    }

    /deep/ .deliver-list-pagination.el-pagination {
        margin: 40px 0 20px 0;
        padding: 0;
        font-weight: normal;
        .btn-next,
        .btn-prev {
            height: 38px;
            border: 1px solid;
        }
        .btn-prev {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }
        .btn-next {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }
        .el-pagination__sizes .el-input {
            width: 87px;
            .el-input__inner {
                color: #666;
                .el-select__caret {
                    color: #999;
                }
            }
        }
        .el-pager {
            border-top: 1px solid;
            border-bottom: 1px solid;
            li {
                min-width: 36px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
            }
        }
        .btn-next,
        .btn-prev,
        .el-pager {
            border-color: #dddddd;
        }
        .el-input__inner,
        .el-pagination__total,
        .el-pagination__jump {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
        }
        .pagination-text {
            color: #999;
            span {
                height: 38px;
                line-height: 38px;
                font-size: 14px;
                .el-input {
                    width: 48px;
                    margin: 0 5px;
                    &__inner {
                        height: 38px;
                        line-height: 38px;
                        padding: 0 5px;
                    }
                }
                &:nth-of-type(2) {
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
        .el-pagination__total,
        .el-pagination__sizes,
        .pagination-text {
            float: right;
        }
    }

    .fllow_resume_dialog {
        .content {
            .tip {
                margin: 20px 0 10px;
                text-align: center;
            }
        }
    }
}
</style>