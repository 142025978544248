<template>
    <div class="corporation-opportunity">
        <list-search
            ref="publicJobSearch"
            :multipleSelection="multipleSelection"
            :checkAll="checkAll"
            @updateParams="search"
            @get-checked-options="getCheckedOptions"
        ></list-search>

        <div class="corporation-opportunity-content">
            <job-list
                v-if="activeTabName == 'jobManage'"
                ref="publicJobList"
                :jobList="newJobList"
                :listLoading="loading"
                @refresh="getJobList"
            ></job-list>

            <delivery-list
                v-if="activeTabName == 'deliveryManage'"
                ref="deliveryList"
                :deliveryList="deliveryList"
                :listLoading="loading"
                @refresh="getDeliveryList"
                @get-multiple-selection="getMultipleSelection"
            ></delivery-list>
        </div>
    </div>
</template>

<script>
import ListSearch from './layout/list-search.vue';
import JobList from './layout/job-list.vue';
import DeliveryList from './layout/delivery-list.vue';
import PublicJobService from '#/js/service/publicJobService.js';

export default {
    components: {
        ListSearch,
        JobList,
        DeliveryList,
    },
    data() {
        return {
            loading: false,
            activeTabName: 'jobManage',

            curIsPublishByMe: '',
            curStatus: '',

            jobIds: [], // 当前列表的职位id集合
            jobStatisticInfoList: [],
            newJobList: [],
            jobList: [],
            deliveryList: [],

            jobSearchParams: {},
            deliverSearchParams: {},

            checkAll: false,
            multipleSelection: [],
        };
    },
    mounted() {
        if(!this.isCompanyOpportunity){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
            return;
        }

        // 大厂机会：职位管理、投递管理
        if(this.$route.query.activeTabName) {
            sessionStorage.setItem('coTabName', this.$route.query.activeTabName);
        }
        // radio选择我发布的职位
        if(this.$route.query.isPublishByMe) {
            this.curIsPublishByMe = this.$route.query.isPublishByMe == 'true';
        }
        // 职位状态
        if(this.$route.query.status) {
            this.curStatus = this.$route.query.status;
        }

        let curActiveTabName = sessionStorage.getItem('coTabName') ? sessionStorage.getItem('coTabName') : 'jobManage';
        this.$set(this.$refs.publicJobSearch, 'activeTabName', curActiveTabName);

        // this.search(this.activeTabName);
        this.search(curActiveTabName);
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCompanyOpportunity() {
            return this.userInfo.privilegeCodeList?.includes('CompanyOpportunity');
        },
    },
    methods: {
        search(activeTabName) {
            this.activeTabName = activeTabName;

            // let params = {};
            if(this.$refs.publicJobSearch) {
                if(activeTabName == 'jobManage') {
                    Object.assign(this.jobSearchParams, this.$refs.publicJobSearch.jobSearchForm);
                    
                    // IM消息那边跳转过来 参数带在url上
                    if(this.curIsPublishByMe == 'true') {
                        this.jobSearchParams.isPublishByMe = true;
                        // 把radio显示改成对应的选择
                        this.$set(this.$refs.publicJobSearch.jobSearchForm, 'isPublishByMe', true);
                    }
                    if(this.curStatus) {
                        this.jobSearchParams.status = this.curStatus;
                        // 把状态显示改成对应的状态
                        this.$set(this.$refs.publicJobSearch.jobSearchForm, 'status', this.curStatus);
                    }
                    console.log(this.jobSearchParams);

                    this.getJobList();
                } else {
                    Object.assign(this.deliverSearchParams, this.$refs.publicJobSearch.deliverSearchForm);

                    this.getDeliveryList();
                }
            }

            // 重置
            this.curIsPublishByMe = '';
            this.curStatus = '';
        },

        getCheckedOptions() {
            this.$refs.deliveryList.$refs.deliveryListTable.toggleAllSelection();
        },
        getMultipleSelection(multipleSelection, checkAll) {
            // console.log(multipleSelection);
            // console.log(checkAll);
            this.multipleSelection = multipleSelection;
            this.checkAll = checkAll;
        },

        // 获取大厂职位统计数（投递、收藏、面试数）
        getPublicJobStatistics() {
            let params = {
                PublicJobIds: this.jobIds
            };
            PublicJobService.getPublicJobStatistics(params)
                .then(res => {
                    // console.log(res);
                    this.jobStatisticInfoList = res;

                    if(this.jobStatisticInfoList.length > 0) {
                        this.jobList.forEach(item => {
                            this.jobStatisticInfoList.forEach(el => {
                                if(item.id.indexOf(el.publicJobId) != -1) {
                                    item.jobStatisticInfo = el;
                                }
                            })
                        })
                        this.newJobList = this.jobList;
                    } else {
                        this.newJobList = this.jobList;
                    }
                    // console.log(this.newJobList);
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
        getJobList(pager, slient) {
            this.loading = true;
            this.jobList = [];

            if(!pager) {
                pager = {
                    start: 0,
                    take: 10
                }
            }
            let params = Object.assign({}, this.jobSearchParams, pager);
            // 查看我的或全部
            if(params.isPublishByMe) {
                let ownerIds = [];
                ownerIds.push(this.userInfo.id);
                params.ownerIds = ownerIds;
            } else {
                params.ownerIds = [];
            }
            // 薪酬范围
            if(params.payment == 1) {
                params.minSalary = '';
                params.maxSalary = 10;
            } else if(params.payment == 2) {
                params.minSalary = 10;
                params.maxSalary = 30;
            } else if(params.payment == 3) {
                params.minSalary = 30;
                params.maxSalary = 50;
            } else if(params.payment == 4) {
                params.minSalary = 50;
                params.maxSalary = 80;
            } else if(params.payment == 5) {
                params.minSalary = 80;
                params.maxSalary = 100;
            } else if(params.payment == 6) {
                params.minSalary = 100;
                params.maxSalary = '';
            } else {
                params.minSalary = '';
                params.maxSalary = '';
            }
            // console.log('获取大厂职位列表========');
            // console.log(params);

            PublicJobService.getPublicJobList(params)
                .then(res => {
                    this.loading = false;
                    this.jobList = res.list;
                    // console.log(this.jobList);

                    this.$set(this.$refs.publicJobList.pager, 'total', res.total);
                    this.$set(this.$refs.publicJobList.pager, 'current', params.start/params.take + 1);
                    this.$set(this.$refs.publicJobList.pager, 'take', params.take);

                    this.$nextTick(() => {
                        if(slient) {
                            document.querySelector('.corporation-opportunity').scrollTop = document.querySelector('.public-job-content').offsetTop;
                        } else if(slient !== null) {
                            document.querySelector('.corporation-opportunity').scrollTop = document.querySelector('.public-job-search').offsetTop;
                        }
                    });
                    // this.newJobList = this.jobList;

                    // 每次查找之后都把jobIds置空
                    this.jobIds = [];
                    this.jobList.forEach( el => {
                        this.jobIds.push(el.id);
                    })
                    this.getPublicJobStatistics();
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
        getDeliveryList(pager, slient) {
            this.loading = true;

            if(!pager) {
                pager = {
                    start: 0,
                    take: 10
                }
            }
            let params = Object.assign({}, this.deliverSearchParams, pager);
            // 投递管理 查看所有投递JobFollowUpFilter: 0，只能看到自己的JobFollowUpFilter: 1
            params.JobFollowUpFilter = 1; 
            // console.log('获取投递列表========');
            // console.log(params);

            PublicJobService.getPublicJobDeliverList(params)
                .then(res => {
                    this.loading = false;
                    this.deliveryList = res.list;

                    this.$set(this.$refs.deliveryList.pager, 'total', res.total);
                    this.$set(this.$refs.deliveryList.pager, 'current', params.start/params.take + 1);
                    this.$set(this.$refs.deliveryList.pager, 'take', params.take);

                    this.$nextTick(() => {
                        if(slient) {
                            document.querySelector('.corporation-opportunity').scrollTop = document.querySelector('.delivery-list').offsetTop;
                        } else if(slient !== null) {
                            document.querySelector('.corporation-opportunity').scrollTop = document.querySelector('.public-job-search').offsetTop;
                        }
                    });
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
    }
};
</script>

<style lang="scss" scope>
    .corporation-opportunity {
        height: 100%;
        overflow-y: auto;
        padding: 20px;
        // display: flex;
        // justify-content: space-between;
    }
</style>