var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "selectOrShield",
      staticClass: "customer-select-input",
      attrs: {
        disabled: _vm.isDisabled,
        "filter-method": _vm.customerFilter,
        "multiple-limit": 5,
        placeholder: _vm.placeholder,
        clearable: "",
        "collapse-tags": "",
        filterable: "",
        multiple: "",
        "popper-class": "customer-select-shield",
      },
      on: {
        clear: function ($event) {
          return _vm.handleCustomerChange({
            customerId: "",
            customerName: "",
          })
        },
        "visible-change": _vm.handleVisibleChange,
      },
      model: {
        value: _vm.customerId,
        callback: function ($$v) {
          _vm.customerId = $$v
        },
        expression: "customerId",
      },
    },
    [
      _vm._l(_vm.customerFilterList, function (customer) {
        return _c(
          "el-option",
          {
            key: customer.customerId,
            attrs: { label: customer.customerName, value: customer.customerId },
          },
          [
            _c("div", { staticClass: "opt-item" }, [
              _c(
                "div",
                {
                  staticClass: "opt-item-l ellipsis",
                  attrs: { title: customer.customerName },
                },
                [_vm._v(_vm._s(customer.customerName))]
              ),
              _c("div", { staticClass: "opt-item-r" }, [
                _c("i", { staticClass: "el-icon-check" }),
              ]),
            ]),
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "select-btns-wrap" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn shield",
              on: {
                click: function ($event) {
                  return _vm.handleBtnHold(0)
                },
              },
            },
            [_vm._v("屏蔽")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn search",
              on: {
                click: function ($event) {
                  return _vm.handleBtnHold(1)
                },
              },
            },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }