var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-job-search" },
    [
      _c(
        "div",
        { staticClass: "job-top" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "职位管理", name: "jobManage" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "投递管理", name: "deliveryManage" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.activeTabName == "jobManage"
        ? [
            _c(
              "el-form",
              {
                ref: "jobSearchForm",
                staticClass: "job-search-form",
                attrs: { model: _vm.jobSearchForm },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "input-inner-label" },
                  [
                    _c("span", { staticClass: "form-input-label" }, [
                      _vm._v("职位"),
                    ]),
                    _c("el-input", {
                      staticClass: "search-input search-input-jobname",
                      attrs: { maxlength: 50, placeholder: "搜索职位名称" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return ((ev) => _vm.addParams()).apply(
                            null,
                            arguments
                          )
                        },
                      },
                      model: {
                        value: _vm.jobSearchForm.jobName,
                        callback: function ($$v) {
                          _vm.$set(_vm.jobSearchForm, "jobName", $$v)
                        },
                        expression: "jobSearchForm.jobName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-inner-label" },
                  [
                    _c("span", { staticClass: "form-input-label" }, [
                      _vm._v("原客户"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "search-select customer-select-input",
                        attrs: {
                          "popper-class": "customer-select-popover",
                          placeholder: "搜索原客户简称/全称",
                          multiple: "",
                          "multiple-limit": 5,
                          "collapse-tags": "",
                          filterable: "",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.addParams()
                          },
                        },
                        model: {
                          value: _vm.jobSearchForm.customerIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.jobSearchForm, "customerIds", $$v)
                          },
                          expression: "jobSearchForm.customerIds",
                        },
                      },
                      _vm._l(_vm.customerList, function (customer) {
                        return _c("el-option", {
                          key: customer.customerId,
                          attrs: {
                            label: customer.customerName,
                            value: customer.customerId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-inner-label" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeSearchProjectType },
                        model: {
                          value: _vm.jobSearchForm.isPublishByMe,
                          callback: function ($$v) {
                            _vm.$set(_vm.jobSearchForm, "isPublishByMe", $$v)
                          },
                          expression: "jobSearchForm.isPublishByMe",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: false } }, [
                          _vm._v("查看全部"),
                        ]),
                        _c("el-radio", { attrs: { label: true } }, [
                          _vm._v("我发布的"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-outer-label",
                    attrs: { label: "工作经验：" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "search-radio-group",
                        on: { change: (val) => _vm.addParams() },
                        model: {
                          value: _vm.jobSearchForm.minYearOfExperience,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobSearchForm,
                              "minYearOfExperience",
                              $$v
                            )
                          },
                          expression: "jobSearchForm.minYearOfExperience",
                        },
                      },
                      _vm._l(_vm.yearOfExperienceMap, function (yearOfExp) {
                        return _c(
                          "el-radio",
                          {
                            key: yearOfExp.value,
                            staticClass: "search-radio",
                            attrs: { label: yearOfExp.value },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(yearOfExp.label) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-outer-label",
                    attrs: { label: "学历要求：" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "search-radio-group",
                        on: { change: (val) => _vm.addParams() },
                        model: {
                          value: _vm.jobSearchForm.minDegree,
                          callback: function ($$v) {
                            _vm.$set(_vm.jobSearchForm, "minDegree", $$v)
                          },
                          expression: "jobSearchForm.minDegree",
                        },
                      },
                      _vm._l(_vm.degreeMap, function (degree) {
                        return _c(
                          "el-radio",
                          {
                            key: degree.value,
                            staticClass: "search-radio",
                            attrs: { label: degree.value },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(degree.label) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-outer-label",
                    attrs: { label: "薪酬范围：" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "search-radio-group",
                        on: { change: (val) => _vm.addParams() },
                        model: {
                          value: _vm.jobSearchForm.payment,
                          callback: function ($$v) {
                            _vm.$set(_vm.jobSearchForm, "payment", $$v)
                          },
                          expression: "jobSearchForm.payment",
                        },
                      },
                      _vm._l(_vm.paymentMap, function (degree) {
                        return _c(
                          "el-radio",
                          {
                            key: degree.value,
                            staticClass: "search-radio",
                            attrs: { label: degree.value },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(degree.label) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "job-search-bottom" }, [
              _c(
                "div",
                { staticClass: "job-search-select" },
                [
                  _c("span", { staticClass: "job-search-dropdown-title" }, [
                    _vm._v("状态："),
                  ]),
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "job-search-dropdown",
                      attrs: { trigger: "click" },
                      on: {
                        command: (val) => _vm.handleJobDropdown(val, "status"),
                        "visible-change": (val) =>
                          _vm.handleDropdownVisible(val, "status"),
                      },
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.statusText) +
                            "\n                        "
                        ),
                        _c("i", {
                          staticClass: "el-icon--right",
                          class: {
                            "el-icon-arrow-down": !_vm.statusDropdownVisible,
                            "el-icon-arrow-up": _vm.statusDropdownVisible,
                          },
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "job-search-dropdown-menu",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.jobStatusList, function (status) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: status.value,
                              class: {
                                active:
                                  _vm.jobSearchForm.status == status.value,
                              },
                              attrs: { command: status.value },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(status.label) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm.activeTabName == "deliveryManage"
        ? [
            _c(
              "el-form",
              {
                ref: "deliverSearchForm",
                staticClass: "job-search-form",
                attrs: { model: _vm.deliverSearchForm },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "input-inner-label" },
                  [
                    _c("span", { staticClass: "form-input-label" }, [
                      _vm._v("岗位"),
                    ]),
                    _c("el-input", {
                      staticClass: "search-input search-input-jobname",
                      attrs: { maxlength: 50, placeholder: "搜索岗位名称" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return ((ev) => _vm.addParams()).apply(
                            null,
                            arguments
                          )
                        },
                      },
                      model: {
                        value: _vm.deliverSearchForm.publicJobName,
                        callback: function ($$v) {
                          _vm.$set(_vm.deliverSearchForm, "publicJobName", $$v)
                        },
                        expression: "deliverSearchForm.publicJobName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-inner-label" },
                  [
                    _c("span", { staticClass: "form-input-label" }, [
                      _vm._v("原客户"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "search-select customer-select-input",
                        attrs: {
                          "popper-class": "customer-select-popover",
                          placeholder: "搜索原客户简称/全称",
                          multiple: "",
                          "multiple-limit": 5,
                          "collapse-tags": "",
                          filterable: "",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.addParams()
                          },
                        },
                        model: {
                          value: _vm.deliverSearchForm.customerIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.deliverSearchForm, "customerIds", $$v)
                          },
                          expression: "deliverSearchForm.customerIds",
                        },
                      },
                      _vm._l(_vm.customerList, function (customer) {
                        return _c("el-option", {
                          key: customer.customerId,
                          attrs: {
                            label: customer.customerName,
                            value: customer.customerId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-inner-label" },
                  [
                    _c("span", { staticClass: "form-input-label" }, [
                      _vm._v("状态"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "search-select",
                        attrs: { placeholder: "请选择状态" },
                        on: {
                          change: function ($event) {
                            return _vm.addParams()
                          },
                        },
                        model: {
                          value: _vm.deliverSearchForm.jobDeliveredStatus,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.deliverSearchForm,
                              "jobDeliveredStatus",
                              $$v
                            )
                          },
                          expression: "deliverSearchForm.jobDeliveredStatus",
                        },
                      },
                      _vm._l(_vm.deliverStatusList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "job-search-bottom" }, [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  { staticClass: "batch-option" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.isSelectAll,
                          callback: function ($$v) {
                            _vm.isSelectAll = $$v
                          },
                          expression: "isSelectAll",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        全选\n                    "
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "info-text" }, [
                      _vm._v("已选择"),
                      _c("i", [_vm._v(_vm._s(_vm.multipleSelection.length))]),
                      _vm._v("份简历"),
                    ]),
                    _c(
                      "el-button",
                      {
                        class: {
                          "batch-btn": true,
                          "batch-view": _vm.multipleSelection.length > 0,
                        },
                        attrs: { disabled: _vm.multipleSelection.length == 0 },
                        on: { click: _vm.batchView },
                      },
                      [
                        _vm._v(
                          "\n                        批量查看\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }