var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "corporation-opportunity" },
    [
      _c("list-search", {
        ref: "publicJobSearch",
        attrs: {
          multipleSelection: _vm.multipleSelection,
          checkAll: _vm.checkAll,
        },
        on: {
          updateParams: _vm.search,
          "get-checked-options": _vm.getCheckedOptions,
        },
      }),
      _c(
        "div",
        { staticClass: "corporation-opportunity-content" },
        [
          _vm.activeTabName == "jobManage"
            ? _c("job-list", {
                ref: "publicJobList",
                attrs: { jobList: _vm.newJobList, listLoading: _vm.loading },
                on: { refresh: _vm.getJobList },
              })
            : _vm._e(),
          _vm.activeTabName == "deliveryManage"
            ? _c("delivery-list", {
                ref: "deliveryList",
                attrs: {
                  deliveryList: _vm.deliveryList,
                  listLoading: _vm.loading,
                },
                on: {
                  refresh: _vm.getDeliveryList,
                  "get-multiple-selection": _vm.getMultipleSelection,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }