<template>
    <div class="public-job-search">
        <div class="job-top">
            <el-tabs v-model="activeTabName" @tab-click="handleClick">
                <el-tab-pane label="职位管理" name="jobManage"></el-tab-pane>
                <el-tab-pane label="投递管理" name="deliveryManage"></el-tab-pane>
            </el-tabs>
        </div>

        <template v-if="activeTabName == 'jobManage'">
            <el-form
                ref="jobSearchForm"
                class="job-search-form"
                :model="jobSearchForm">
                <el-form-item class="input-inner-label">
                    <span class="form-input-label">职位</span>
                    <el-input
                        v-model="jobSearchForm.jobName"
                        :maxlength="50"
                        placeholder="搜索职位名称"
                        class="search-input search-input-jobname"
                        @keyup.enter.native="(ev) => addParams()"
                    ></el-input>
                </el-form-item>

                <!-- <el-form-item class="input-inner-label">
                    <span class="form-input-label">原客户名</span>
                    <el-input
                        v-model="jobSearchForm.customerName"
                        :maxlength="50"
                        placeholder="搜索原公司名"
                        class="search-input search-input-jobname"
                        @keyup.enter.native="(ev) => addParams()"
                    ></el-input>
                </el-form-item> -->
                <el-form-item class="input-inner-label">
                    <span class="form-input-label">原客户</span>
                    <el-select 
                        v-model="jobSearchForm.customerIds"
                        class="search-select customer-select-input"
                        popper-class="customer-select-popover"
                        placeholder="搜索原客户简称/全称"
                        multiple
                        :multiple-limit="5"
                        collapse-tags
                        filterable
                        clearable
                        @change="addParams()"
                    >
                        <el-option
                            :key="customer.customerId"
                            :label="customer.customerName"
                            :value="customer.customerId"
                            v-for="customer in customerList"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="input-inner-label">
                    <el-radio-group v-model="jobSearchForm.isPublishByMe" @change="changeSearchProjectType">
                        <el-radio :label="false">查看全部</el-radio>
                        <el-radio :label="true">我发布的</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="工作经验：" class="input-outer-label">
                    <el-radio-group
                        class="search-radio-group"
                        v-model="jobSearchForm.minYearOfExperience"
                        @change="(val) => addParams()">
                        <el-radio
                            class="search-radio"
                            v-for="yearOfExp in yearOfExperienceMap"
                            :key="yearOfExp.value"
                            :label="yearOfExp.value">
                            {{yearOfExp.label}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="学历要求：" class="input-outer-label">
                    <el-radio-group
                        class="search-radio-group"
                        v-model="jobSearchForm.minDegree"
                        @change="(val) => addParams()">
                        <el-radio
                            class="search-radio"
                            v-for="degree in degreeMap"
                            :key="degree.value"
                            :label="degree.value">
                            {{degree.label}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="薪酬范围：" class="input-outer-label">
                    <el-radio-group
                        class="search-radio-group"
                        v-model="jobSearchForm.payment"
                        @change="(val) => addParams()">
                        <el-radio
                            class="search-radio"
                            v-for="degree in paymentMap"
                            :key="degree.value"
                            :label="degree.value">
                            {{degree.label}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <div class="job-search-bottom">
                <!-- <div class="search-param-list">
                    您的搜索结果数：
                    <el-tag
                        closable
                        effect="plain"
                        class="search-param-item"
                        v-for="(search, index) in searchConditionList"
                        :key="index"
                        @close="removeLabel(search)"
                        v-show="search.value">
                        {{search.text}}: {{search.value}}
                    </el-tag>
                    <span class="job-total">
                        共 <span class="text-primary">{{jobNumber | currency('', 0)}}</span> 个职位
                    </span>
                    <span class="clear-search text-primary" @click="handleClearSearch">
                        <i class="el-icon-delete"></i>
                        清空搜索条件
                    </span>
                </div> -->
                <div class="job-search-select">
                    <span class="job-search-dropdown-title">状态：</span>
                    <el-dropdown
                        trigger="click"
                        class="job-search-dropdown"
                        @command="(val) => handleJobDropdown(val, 'status')"
                        @visible-change="(val) => handleDropdownVisible(val, 'status')">
                        <span class="el-dropdown-link">
                            {{statusText}}
                            <i
                                class="el-icon--right"
                                :class="{
                                    'el-icon-arrow-down': !statusDropdownVisible,
                                    'el-icon-arrow-up': statusDropdownVisible
                                }"
                            ></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            class="job-search-dropdown-menu">
                            <el-dropdown-item
                                v-for="status in jobStatusList"
                                :key="status.value"
                                :command="status.value"
                                :class="{'active': jobSearchForm.status == status.value}">
                                {{status.label}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </template>
            
        <template v-if="activeTabName == 'deliveryManage'">
            <el-form
                ref="deliverSearchForm"
                class="job-search-form"
                :model="deliverSearchForm">
                <el-form-item class="input-inner-label">
                    <span class="form-input-label">岗位</span>
                    <el-input
                        v-model="deliverSearchForm.publicJobName"
                        :maxlength="50"
                        placeholder="搜索岗位名称"
                        class="search-input search-input-jobname"
                        @keyup.enter.native="(ev) => addParams()"
                    ></el-input>
                </el-form-item>

                <!-- <el-form-item class="input-inner-label">
                    <span class="form-input-label">大厂公司</span>
                    <el-input
                        v-model="deliverSearchForm.customerName"
                        :maxlength="50"
                        placeholder="搜索大厂公司名称"
                        class="search-input search-input-jobname"
                        @keyup.enter.native="(ev) => addParams()"
                    ></el-input>
                </el-form-item> -->
                <el-form-item class="input-inner-label">
                    <span class="form-input-label">原客户</span>
                    <el-select 
                        v-model="deliverSearchForm.customerIds"
                        class="search-select customer-select-input"
                        popper-class="customer-select-popover"
                        placeholder="搜索原客户简称/全称"
                        multiple
                        :multiple-limit="5"
                        collapse-tags
                        filterable
                        clearable
                        @change="addParams()"
                    >
                        <el-option
                            :key="customer.customerId"
                            :label="customer.customerName"
                            :value="customer.customerId"
                            v-for="customer in customerList"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item class="input-inner-label">
                    <span class="form-input-label">状态</span>
                    <el-select class="search-select" v-model="deliverSearchForm.jobDeliveredStatus" placeholder="请选择状态" @change="addParams()">
                        <el-option
                            v-for="item in deliverStatusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div class="job-search-bottom">
                <!-- <div class="search-param-list">
                    您的搜索结果数：
                    <el-tag
                        closable
                        effect="plain"
                        class="search-param-item"
                        v-for="(search, index) in searchConditionList"
                        :key="index"
                        @close="removeLabel(search)"
                        v-show="search.value">
                        {{search.text}}: {{search.value}}
                    </el-tag>
                    <span class="job-total">
                        共 <span class="text-primary">{{jobNumber | currency('', 0)}}</span> 个职位
                    </span>
                    <span class="clear-search text-primary" @click="handleClearSearch">
                        <i class="el-icon-delete"></i>
                        清空搜索条件
                    </span>
                </div> -->

                <div style="display: flex;">
                    <div class="batch-option">
                        <el-checkbox v-model="isSelectAll" @change="handleCheckAllChange">
                            全选
                        </el-checkbox>

                        <span class="info-text">已选择<i>{{ multipleSelection.length }}</i>份简历</span>

                        <el-button :class="{'batch-btn': true, 'batch-view': multipleSelection.length > 0}" @click="batchView" :disabled="multipleSelection.length == 0">
                            批量查看
                        </el-button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import CustomerSelectShield from '#/component/common/customer-select-shield.vue';
import customerListService from '#/js/service/customerListService.js';

// const defaultSearchLabels = {
//     createDate: {
//         name: "createDate",
//         title: "创建时间",
//         value: ""
//     },
// };

export default {
    props: {
        jobNumber: {
            type: Number,
            default: 0
        },
        checkAll: {
            type: Boolean,
            default: false
        },
        multipleSelection: Array,
        // activeTabName: {
        //     type: String,
        //     default: 'jobManage'
        // }
    },
    components: {
        CustomerSelectShield,
    },
    data() {
        return {
            isSelectAll: false,

            activeTabName: 'jobManage',
            statusDropdownVisible: false,

            customerList: [],

            // 投递状态
            deliverStatusList: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '0',
                    label: '待处理'
                },
                {
                    value: '1',
                    label: '已跟进'
                },
                {
                    value: '2',
                    label: '不通过'
                },
            ],
            // 职位状态
            jobStatusList: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '0',
                    label: '编辑中'
                },
                {
                    value: '3',
                    label: '审核中'
                },
                {
                    value: '5',
                    label: '审核未通过'
                },
                {
                    value: '7',
                    label: '未发布'
                },
                {
                    value: '9',
                    label: '发布中'
                },
                {
                    value: '11',
                    label: '源职位已暂停'
                },
                {
                    value: '13',
                    label: '源职位已完成'
                },
            ],
            yearOfExperienceMap: [
                {
                    value: "",
                    label: "不限"
                }, {
                    value: 1,
                    label: "1-3年"
                }, {
                    value: 3,
                    label: "3-5年"
                }, {
                    value: 5,
                    label: "5-10年"
                }, {
                    value: 10,
                    label: "10年以上"
                }, {
                    value: 0,
                    label: "不要求"
                }
            ],
            degreeMap: [
                {
                    value: "",
                    label: "不限"
                }, {
                    value: 1,
                    label: "高中及以下"
                }, {
                    value: 2,
                    label: "大专"
                }, {
                    value: 3,
                    label: "本科"
                }, {
                    value: 4,
                    label: "硕士"
                }, {
                    value: 5,
                    label: "MBA"
                }, {
                    value: 6,
                    label: "博士"
                }
            ],
            paymentMap: [
                {
                    value: "",
                    label: "不限"
                }, {
                    value: 1,
                    label: "10K以下"
                }, {
                    value: 2,
                    label: "10K-30K"
                }, {
                    value: 3,
                    label: "30K-50K"
                }, {
                    value: 4,
                    label: "50K-80K"
                }, {
                    value: 5,
                    label: "80K-100K"
                }, {
                    value: 6,
                    label: "100K以上"
                },
            ],
            jobSearchForm: {
                status: "", 

                jobName: '',
                customerIds: [],
                isPublishByMe: true,
                minYearOfExperience: '',
                minDegree: '',
                payment: '',
            },
            deliverSearchForm: {
                jobDeliveredStatus: '', 
                publicJobName: '',
                customerIds: [],
            },
            // searchLabels: JSON.parse(JSON.stringify(defaultSearchLabels)),
            // searchConditionList: [] // 查询的tag列表

        }
    },
    computed: {
        statusText() {
            let jobStatusSelected = this.jobStatusList.find(item => item.value === this.jobSearchForm.status);
            return jobStatusSelected?.label || '全部';
        },
        firmId() {
            return this.$store.state.user.userInfo.firmId;
        },
    },
    watch: {
        checkAll: {
            handler(val) {
                this.isSelectAll = val;
            },
            immediate: true
        },
    },
    mounted() {
        this.getCustomerList();
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
            this.activeTabName = tab.name;

            sessionStorage.setItem('coTabName', this.activeTabName);

            // tab切换，清空上个tab的筛选条件
            this.handleClearSearch();
        },

        getCustomerList () {
            let params = {
                firmId: this.firmId
            };
            customerListService.getMyCompanyCustomers(params)
                .then(res => {
                    res.forEach(item => {
                        delete item.subCustomers;
                    });
                    this.customerList = res;
                }).catch(err => {
                    console.log(err);
                });
        },

        addParams() {
            // let searchList = this.jobSearchForm,
            //     searchLabelList = this.searchLabels;
            // // console.log(searchList);
            // // console.log(searchLabelList);
            // Object.keys(searchList).forEach(item => {
            //     switch(item) {
            //         case 'start':
            //         case 'take':
            //         case 'status':
            //         default:
            //             console.log(searchLabelList[item])
            //             searchLabelList[item].value = searchList[item];
            //     }
            // })

            this.$emit('updateParams', this.activeTabName);

            // this.updateSearchLabel();
        },
        // removeLabel(param) {
        //     let search = this.jobSearchForm;
        //     if(this.searchLabels.hasOwnProperty(param.title)) {
        //         this.searchLabels[param.title].value = "";
        //     }
        //     switch(param.title) {
        //         case 'customerName':
        //         default:
        //             search[param.title] = "";
        //             this.addParams();
        //     }
        // },
        // updateSearchLabel() {
        //     let list = [];
        //     // console.log(this.searchLabels);
        //     Object.values(this.searchLabels).forEach(label => {
        //         if(label.value) {
        //             list.push({
        //                 title: label.name,
        //                 text: label.title,
        //                 value: label.value
        //             })
        //         }
        //     });
        //     this.searchConditionList = list;
        //     // console.log(this.searchConditionList);
        // },

        handleClearSearch() {
            let jobSearchFormParams = {
                status: "", 

                jobName: '',
                customerIds: [],
                isPublishByMe: true,
                minYearOfExperience: '',
                minDegree: '',
                payment: '',
            };
            let deliverSearchFormParams = {
                jobDeliveredStatus: '', 
                publicJobName: '',
                customerIds: '',
            };
            Object.assign(this.jobSearchForm, jobSearchFormParams);
            Object.assign(this.deliverSearchForm, deliverSearchFormParams);

            // this.searchLabels = JSON.parse(JSON.stringify(defaultSearchLabels));
            this.addParams();
        },


        handleDropdownVisible(visible, type) {
            this[`${type}DropdownVisible`] = visible;
        },
        handleJobDropdown(command, type) {
            this.jobSearchForm[type] = command;
            this.addParams()
        },
        changeSearchProjectType(val) {
            // console.log(val);
            this.jobSearchForm.isPublishByMe = val;
            this.addParams();
        },

        // 点击全选
        handleCheckAllChange(val) {
            this.$emit('get-checked-options');
        },
        // 批量查看
        batchView() {
            console.log('点击批量查看');
            // let resumeId = this.multipleSelection.map( item => {
            //     return item.resumeId; // 简历id
            // });
            let id = this.multipleSelection.map( item => {
                return item.id; // 投递id
            });
            if(this.multipleSelection.length > 1) {
                if(sessionStorage.getItem('batchOpportunityResumeSelectIndex')) {
                    sessionStorage.removeItem('batchOpportunityResumeSelectIndex');
                }

                sessionStorage.setItem('batchViewOpportunityResume', JSON.stringify(this.multipleSelection));

                window.open(`/#/batchOpportunityResumeDetail`);
            } else {
                // window.open(`/#/opportunityResumeDetail/${resumeId[0]}`);
                window.open(`/#/opportunityResumeDetail/${id[0]}`);
            }
        },
    }
};
</script>

<style lang="scss">
.customer-select-popover.el-select-dropdown {
    .el-select-dropdown__item {
        width: 544px;
        padding-right: 50px;
    }
}
</style>

<style lang="scss" scope>
.public-job-search {
    position: relative;
    // margin-bottom: 20px;
    // background-color: #ffffff;
    // border-radius: 8px;
    .job-top {
        height: 60px;
        background-color: #ffffff;
        border-radius: 8px 8px 0 0;
        .el-tabs__nav-scroll {
            padding: 0 40px;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
            background-color: #EEEEEE;
        }
        .el-tabs__active-bar {
            height: 4px;
        }
        .el-tabs__item {
            height: 60px;
            line-height: 60px;
            padding: 0 40px;
            color: #999999;
            &.is-active {
                color: #38BC9D;
            }
        }
    }
    .text-primary {
        color: $primary;
    }

    .job-search-form {
        position: relative;
        // padding: 20px;
        padding: 20px 20px 0;
        padding-right: 0;
        background-color: #ffffff;
        border-radius: 0 0 8px 8px;
        margin-bottom: 20px;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .el-form-item {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 20px;
            &.input-inner-label {
                // width: 400px;
                width: 544px;
                .el-form-item__content {
                    position: relative;
                }
            }
            &.input-outer-label {
                .el-form-item__label {
                    text-align: left;
                    margin-right: 5px;
                    margin-bottom: 0;
                    width: 80px;
                    color: #666;
                    padding-right: 0;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: bold;
                }
                .el-form-item__content {
                    line-height: 26px;
                    padding-left: 85px;
                    // height: 26px;
                }
            }
        }
        .form-input-label {
            height: 40px;
            width: 80px;
            text-align: center;
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 1px;
            left: 1px;
            white-space: nowrap;
            // background: #fff;
            line-height: 40px;
            font-size: 14px;
            color: #333;
            border-radius: 4px;
            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 24px;
                background: #ddd;
                position: absolute;
                right: 0;
                top: 9px;
            }
        }
        .isDisabled {
            background-color: #F5F7FA;
            color: #C0C4CC;
        }
        .search-select,
        .search-input {
            width: 100%;
            height: 42px;
            .el-input__inner {
                height: 42px;
                line-height: 42px;
                padding-right: 20px;
                padding-left: 92px;
            }
            &.search-input-key {
                width: 100%;
                .el-input__inner {
                    padding-left: 20px;
                }
            }
            &.el-date-editor {
                padding: 0;
                padding-left: 92px;
                line-height: 42px;
                .el-icon-date {
                    display: none;
                }
                .el-range-separator {
                    line-height: 40px;
                }
                .el-range__close-icon {
                    margin-left: 15px;
                    line-height: 40px;
                }
            }
        }

        .customer-select-input {
            .el-select__tags{
                // .el-tag {
                //     max-width: 200px;
                //     overflow: hidden;
                //     white-space: nowrap;
                //     text-overflow: ellipsis;
                // }
            }
        }

        .el-radio-group.search-radio-group {
            // height: 26px;
            .el-radio {
                line-height: 25px;
                padding: 0 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 2px;
                &:hover {
                    background: #e9e9e9;
                }
                &.is-checked {
                    background: $primary;
                    &:hover {
                        background: $primary;
                    }
                    .el-radio__label {
                        color: #fff;
                    }
                }
                .el-radio__input {
                    display: none;
                }
                .el-radio__label {
                    padding-left: 0;
                }
            }
        }
        .more-job-location-btn {
            display: inline-block;
            line-height: 26px;
            padding: 0 10px;
            margin-right: 5px;
            color: $primary;
            cursor: pointer;
            &:hover {
                background: #e9e9e9;
            }
        }
        .more-btn {
            position: absolute;
            bottom: 12px;
            left: 20px;
            .icon-font {
                font-size: 14px;
                color: $primary;
                cursor: pointer;
            }
            &:hover, &:focus {
                .icon-font {
                    color: #48CAAC;
                    text-decoration: underline;
                }
            }
            &:active {
                .icon-font {
                    color: #36B495;
                    text-decoration: underline;
                }
            }
        }
    }
    .job-search-bottom {
        // width: 1100px;
        width: 100%;
        min-width: 564px;
        height: 60px;
        line-height: 60px;
        background-color: #ffffff;
        border-radius: 8px 8px 0 0;
        padding: 0 20px;
        min-height: 32px;
        border-bottom: 1px solid #EEEEEE;
        .search-param-list {
            .search-param-item.el-tag {
                max-width: 815px;
                white-space: normal;
                line-height: 24px;
                height: auto;
                margin-right: 15px;
                margin-bottom: 8px;
            }
            .job-total {
                display: inline-block;
                margin-bottom: 8px;
            }
            .clear-search {
                display: inline-block;
                margin-bottom: 8px;
                margin-left: 20px;
                cursor: pointer;
            }
        }
        .job-search-select {
            display: inline-block;
            // line-height: 32px;
            line-height: 60px;
            .job-search-dropdown-title {
                color: #666;
            }
            .job-search-dropdown.el-dropdown {
                .el-dropdown-link {
                    // color: #b9b9b9;
                    color: #38BC9D;
                }
            }
            &.job-search-select-jobSource {
                margin-right: 28px;
            }
            &.job-search-select-orderBy {
                margin-left: 28px;
                float: right;
            }
        }
        .job-list-sum {
            float: right;
            // line-height: 32px;
            line-height: 60px;
        }

        .batch-option {
            margin-left: 15px;
            margin-right: 30px;
            .el-checkbox__inner {
                border-color: #999;
            }
            .batch-btn {
                border-radius: 4px;
                background-color: #fff;
                color: #999999;
                border: 1px solid #DDDDDD;
            }
            .batch-view {
                color: #fff;
                background-color: #38BC9D;
                &:hover {
                    background-color: #48CAAC;
                }
            }
            .info-text {
                margin-left: 20px;
                margin-right: 25px;
                i {
                    color: #F4A623;
                    margin: 0 5px;
                }
            }
        }

        .el-checkbox {
            .el-checkbox__label {
                color: #666;
                font-size: 14px;
            }
            &.is-checked {
                .el-checkbox__inner {
                    border-color: $primary;
                    background: #fff;
                    &::after {
                        top: 0;
                        border-color: #38bc9d;
                    }
                }
                .el-checkbox__label {
                    color: #666;
                }
            }
        }
        .sort-dropdown {
            .el-dropdown-selfdefine {
                color: #666;
            }
        }

    }
    .team-select-box {
        width: 480px;
        height: 371px;
        margin-top: 5px;
        position: absolute;
        z-index: 10;
        background: #fff;
        box-shadow: 2px 2px 6px #999;
        .team-select.visible {
            .team-select-bd {
                margin-top: 5px;
            }
        }
        .button-list {
            margin-top: 333px;
            margin-left: 15px;
        } 
    }
    // .search-param-container {
    //     margin-bottom: 10px;
    //     background: #fff;
    //     .search-param-list {
    //         background: #f6f6f6;
    //         padding: 8px 20px 0;
    //         .search-param-item.el-tag {
    //             max-width: 815px;
    //             white-space: normal;
    //             line-height: 24px;
    //             height: auto;
    //             margin-right: 15px;
    //             margin-bottom: 8px;
    //         }
    //         .job-total {
    //             display: inline-block;
    //             margin-bottom: 8px;
    //         }
    //         .clear-search {
    //             display: inline-block;
    //             margin-bottom: 8px;
    //             margin-left: 20px;
    //             cursor: pointer;
    //         }
    //     }
    // }
}
</style>

<style lang="scss">
    .job-search-dropdown-menu.el-dropdown-menu {
        margin-top: 0;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item.active {
            background-color: #ebf8f5;
            color: $primary;
        }
    }
    .city-select-dialog.el-dialog {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            position: relative;
        }
        .city-select-dialog-close {
            position: absolute;
            right: 20px;
            top: 16px;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            color: #b9b9b9;
            &:hover {
                color: #cf0224;
            }
        }
    }
</style>
