var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-job-item" },
    [
      _c("div", { staticClass: "public-job-item-header" }, [
        _c("div", { staticClass: "job-title" }, [
          _c(
            "a",
            {
              staticClass: "job-title-link text-ellipsis",
              attrs: {
                target: "_blank",
                title: _vm.job.name,
                href: `/Headhunting/MyCompany.html#/opportunityJobDetail/${_vm.job.id}?jobId=${_vm.job.job.id}&userId=${_vm.job.job.recruiter.userId}`,
              },
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.job.name) + "\n            "
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "job-customerName",
              attrs: { title: _vm.job.customerName },
            },
            [_vm._v(_vm._s(_vm.job.customerName))]
          ),
        ]),
      ]),
      _c("div", { staticClass: "public-job-item-body" }, [
        _c("div", { staticClass: "job-intro" }, [
          _c("div", { staticClass: "job-info" }, [
            _c("span", { staticClass: "job-info-item" }, [
              _vm._v(
                _vm._s(
                  _vm.job.minSalary == 0 && _vm.job.maxSalary == 0
                    ? "面议"
                    : _vm.job.minSalary + "K-" + _vm.job.maxSalary + "K"
                )
              ),
            ]),
            _vm.job.minYearOfExperience == 0
              ? _c("span", { staticClass: "job-info-item" }, [
                  _vm._v("经验不限"),
                ])
              : _c("span", { staticClass: "job-info-item" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("filterYearOfExperience")(
                        _vm.job.minYearOfExperience
                      )
                    ) + "经验"
                  ),
                ]),
            _c("span", { staticClass: "job-info-item" }, [
              _vm._v(_vm._s(_vm._f("degreeFilter")(_vm.job.minDegree))),
            ]),
          ]),
          _c("div", { staticClass: "job-origin" }, [
            _c("span", { staticClass: "icon-text" }, [_vm._v("原")]),
            _c("span", { staticClass: "job" }, [
              _vm._v(_vm._s(_vm.job.job.name)),
            ]),
            _c("span", { staticClass: "customer" }, [
              _vm._v(_vm._s(_vm.job.job.customerName)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "job-status" }, [
          _c("div", [
            _vm.job.status == "0"
              ? _c("span", { staticClass: "recruit-status" }, [
                  _vm._v("编辑中"),
                ])
              : _vm._e(),
            _vm.job.status == "3"
              ? _c("span", { staticClass: "recruit-status" }, [
                  _vm._v("审核中"),
                ])
              : _vm._e(),
            _vm.job.status == "5"
              ? _c(
                  "div",
                  [
                    _c("span", { staticClass: "recruit-status no-pass" }, [
                      _vm._v("审核未通过"),
                    ]),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "240",
                          trigger: "hover",
                          content: _vm.job.rejectReason,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "nopass-img",
                          attrs: {
                            slot: "reference",
                            src: require("@src/assets/images/program-goal-warn.png"),
                            alt: "",
                          },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.job.status == "7"
              ? _c("span", { staticClass: "recruit-status" }, [
                  _vm._v("未发布"),
                ])
              : _vm._e(),
            _vm.job.status == "9"
              ? _c("span", { staticClass: "recruit-status publish-status" }, [
                  _vm._v("发布中"),
                ])
              : _vm._e(),
            _vm.job.status == "11"
              ? _c("span", { staticClass: "recruit-status" }, [
                  _vm._v("源职位已暂停"),
                ])
              : _vm._e(),
            _vm.job.status == "13"
              ? _c("span", { staticClass: "recruit-status" }, [
                  _vm._v("源职位已完成"),
                ])
              : _vm._e(),
          ]),
          _c("span", { staticClass: "job-date" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm._f("createDateFilter")(_vm.job.created)) +
                " 发布 / " +
                _vm._s(_vm._f("dateFilter")(_vm.job.updated)) +
                " 更新\n            "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "public-job-item-body-right" },
          [
            [
              _c("avatar", {
                staticClass: "user-avatar",
                attrs: {
                  size: "sm",
                  src: _vm.job.job.recruiter.avatarUrl,
                  userId: _vm.job.job.recruiter.userId,
                  enableCard: true,
                  enableLink: true,
                },
              }),
              _c("div", { staticClass: "job-number" }, [
                _c("span", { attrs: { title: "投递" } }, [
                  _vm._v(
                    "\n                        投递 " +
                      _vm._s(_vm.job.jobStatisticInfo?.deliveredCount || 0) +
                      "\n                    "
                  ),
                ]),
                _c("span", { attrs: { title: "收藏" } }, [
                  _vm._v(
                    "\n                        收藏 " +
                      _vm._s(_vm.job.jobStatisticInfo?.collectionCount || 0) +
                      "\n                    "
                  ),
                ]),
                _c("span", { attrs: { title: "面试" } }, [
                  _vm._v(
                    "\n                        面试 " +
                      _vm._s(_vm.job.jobStatisticInfo?.interviewCount || 0) +
                      "\n                    "
                  ),
                ]),
              ]),
              _vm.job.job.recruiter.userId == _vm.userInfo.id
                ? _c(
                    "el-dropdown",
                    {
                      attrs: { placement: "bottom" },
                      on: { command: _vm.handlePublicJobMoreOperate },
                    },
                    [
                      _c("span", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.moreOperateLoading,
                            expression: "moreOperateLoading",
                          },
                        ],
                        staticClass: "el-dropdown-link el-icon-more",
                      }),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "job-more-opr-dropdown",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.job.canCancelAudit
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "cancelAudit" } },
                                    [_vm._v("撤回审核")]
                                  )
                                : _vm._e(),
                              _vm.job.canCancelPulish
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "cancelPublish" } },
                                    [_vm._v("取消发布")]
                                  )
                                : _vm._e(),
                              _vm.job.canSubmitAudit
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "submitAudit" } },
                                    [_vm._v("提交审核")]
                                  )
                                : _vm._e(),
                              _vm.job.canPulish
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "publish" } },
                                    [_vm._v("发布")]
                                  )
                                : _vm._e(),
                              _vm.job.canEdit
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "edit" } },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
          ],
          2
        ),
      ]),
      _c("famous-company-dialog", {
        ref: "famousCompanyDialog",
        on: {
          editSuccess: function ($event) {
            return _vm.$emit("updateParams")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }