var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delivery-list" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "deliveryListTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.deliveryList,
            border: "",
            "tooltip-effect": "light",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "42",
              align: "center",
              type: "selection",
              "header-align": "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "realName",
              align: "left",
              width: "178",
              label: "姓名",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "created",
              align: "left",
              width: "166",
              label: "投递时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("timeFilter")(scope.row.created))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "publicJobName",
              align: "left",
              width: "162",
              label: "投递岗位",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "table-a",
                        attrs: {
                          href: `/Headhunting/MyCompany.html#/opportunityJobDetail/${scope.row.publicJobId}?jobId=${scope.row.jobId}&userId=${_vm.userInfo.id}`,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.publicJobName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerName",
              align: "left",
              width: "162",
              label: "大厂公司",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "birthday",
              align: "left",
              width: "95",
              label: "年龄",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.birthdayToAge(scope.row.birthday)
                            ? _vm.birthdayToAge(scope.row.birthday) + "岁"
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "degree",
              align: "left",
              width: "120",
              label: "学历",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("degreeFilter")(scope.row.degree))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "workExperiences",
              align: "left",
              "min-width": "350",
              "show-overflow-tooltip": true,
              label: "最近工作",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.workExperiences,
                    function (item, index) {
                      return _c("div", { key: "workExperiences_" + index }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("dateFilter")(item.startDate)) +
                              "-" +
                              _vm._s(_vm._f("dateFilter")(item.endDate))
                          ),
                        ]),
                        _c("i", { staticClass: "line" }, [_vm._v("|")]),
                        _c("span", [_vm._v(_vm._s(item.company))]),
                        _c("i", { staticClass: "line" }, [_vm._v("|")]),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              align: "left",
              width: "110",
              label: "状态",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("span", [_vm._v("待处理")])
                      : _vm._e(),
                    scope.row.status == 1
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              staticClass: "table-a followed",
                              attrs: {
                                href: `/Headhunting/MyCompany.html#/candidateDetail/${scope.row.candidateId}/recommendation/${scope.row.recommendId}`,
                                target: "_blank",
                              },
                            },
                            [_vm._v("已跟进>>")]
                          ),
                        ])
                      : _vm._e(),
                    scope.row.status == 2
                      ? _c("span", [_vm._v("不通过")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "item-option" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleOption(scope.row, "view")
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      scope.row.status == 0 &&
                      scope.row.followUpUsers.includes(_vm.userInfo.id)
                        ? _c("i", { staticClass: "line" }, [_vm._v("|")])
                        : _vm._e(),
                      scope.row.status == 0 &&
                      scope.row.followUpUsers.includes(_vm.userInfo.id)
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleOption(scope.row, "follow")
                                },
                              },
                            },
                            [_vm._v("跟进")]
                          )
                        : _vm._e(),
                      scope.row.status == 0 &&
                      scope.row.followUpUsers.includes(_vm.userInfo.id)
                        ? _c("i", { staticClass: "line" }, [_vm._v("|")])
                        : _vm._e(),
                      scope.row.status == 0 &&
                      scope.row.followUpUsers.includes(_vm.userInfo.id)
                        ? _c(
                            "span",
                            {
                              staticStyle: { color: "#fc7859" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOption(scope.row, "refuse")
                                },
                              },
                            },
                            [_vm._v("不通过")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-pagination",
        {
          staticClass: "deliver-list-pagination",
          attrs: {
            "current-page": _vm.pager.current,
            "page-sizes": [10, 30, 50],
            "page-size": _vm.pager.take,
            layout: "prev, pager, next, slot, total, sizes",
            total: _vm.pager.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        },
        [
          _c("span", { staticClass: "pagination-text" }, [
            _c(
              "span",
              [
                _vm._v("\n                前往\n                "),
                _c("el-input", {
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handlePagerJump(_vm.pagerJump)
                    },
                  },
                  model: {
                    value: _vm.pagerJump,
                    callback: function ($$v) {
                      _vm.pagerJump = $$v
                    },
                    expression: "pagerJump",
                  },
                }),
                _vm._v("\n                页\n            "),
              ],
              1
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.handlePagerJump(_vm.pagerJump)
                  },
                },
              },
              [_vm._v(" 跳转 ")]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "fllow_resume_dialog",
          attrs: {
            title: "跟进简历",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "560px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("p", { staticClass: "tip" }, [
              _vm._v("候选人已存在于人才库，去对比补充后跟进"),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.goToCompareResume },
                },
                [_vm._v("去对比补充")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("fllow-resume-dialog", {
        ref: "fllowResumeDialog",
        on: { "update-deliver-list": _vm.updateDeliverList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }